.signup {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  width: 100%;
  background-image: url('../img//budget.jpg');
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  
}
.forms{
    height:60%;
    width: 60%;
    border: 25px solid #FF914D;
    padding-left: 50px;
    padding-right: 50px;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
input{
    padding: 10px;
    margin-bottom: 10px;
    width: 100%;
    height: 50px;
    border-radius: 5px;
    border-width: 2px;
    border-color: #FF914D;
}
.inputs{
    font-size: 22px;
    outline: none;
    color: black;
}
.submit-btns{
    font-size: 25px;
    width: 200px;
    padding: 10px;
    background-color: #FF914D;
    opacity: 0.8;
    border-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}
.registerbtn{
    outline: none;
    font-size: 25px;
    border: none;
    background: none;
    color: white;
    font-weight: 700;
    cursor: pointer;
}
.error{
    margin-bottom:10px;
    }
    .signuplink{
      font-size:20px;
    }
.logo{
    height:80px;
    width: 80px;
    border: 2px solid white;
    border-radius: 50%;
    margin-bottom: 20px;
}